.Card{
    width: 100%;
    padding: 8px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    color:white;
    height: 200px;
    padding: 15px;
    border-radius: 15PX;
}

.iconCard{
    width: 75px;
    height: 75px;
    border-radius: 10px;
    padding: 10px;
    background-color: white;
    color: black;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}