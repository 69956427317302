.Layout {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .row {
        height: -webkit-fill-available;
        overflow: hidden;

        .col {
            height: 100%;
            overflow: auto;
        }
    }
}