.Dashboard {
    width: 100%;
    height: fit-content;

    .headerContainer {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        .titleContainer {
            display: flex;
            flex-direction: column;
            margin-right: auto;
            padding-right: 10px;
        }

        .viewButton {
            display: flex;
            background-color: transparent;
            border: none;
            white-space: nowrap;
            color: white;
            font-size: 14px;
            font-weight: 400;

            &:focus-visible,
            &:focus-within {
                outline: none;
            }
        }
    }

    .matchRow {
        width: 100%;
        margin: auto;

    }
}