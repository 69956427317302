@import "../../../global";

.Menu {
    width: 100%;
    height: 100%;
    overflow: auto;

    .option {
        padding: 20px 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 18px;

        // background-color: #0D0D1C;
        background-color: $primaryColor;
        color: white;
        cursor: pointer;

        span.icon {
            display: flex;
            font-size: .8rem;
            margin-right: 15px;
        }
    }

    .active {
        border-color: $primaryColor;
        border-style: solid;
        border-width: 1px;
    }
}