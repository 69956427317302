@font-face {
    font-family: 'PlusJakartaSans';
    font-style: normal;
    font-weight: bolder;
    src: url("./fonts/PlusJakartaSans-Bold.ttf") format("truetype");
}

@font-face {
    font-family: 'PlusJakartaSans';
    font-style: italic;
    font-weight: bolder;
    src: url("./fonts/PlusJakartaSans-BoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: 'PlusJakartaSans';
    font-style: normal;
    font-weight: 800;
    src: url("./fonts/PlusJakartaSans-ExtraBold.ttf") format("truetype");
}

@font-face {
    font-family: 'PlusJakartaSans';
    font-style: italic;
    font-weight: 800;
    src: url("./fonts/PlusJakartaSans-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: 'PlusJakartaSans';
    font-style: normal;
    font-weight: 200;
    src: url("./fonts/PlusJakartaSans-ExtraLight.ttf") format("truetype");
}

@font-face {
    font-family: 'PlusJakartaSans';
    font-style: italic;
    font-weight: 200;
    src: url("./fonts/PlusJakartaSans-ExtraLightItalic.ttf") format("truetype");
}

@font-face {
    font-family: 'PlusJakartaSans';
    font-style: italic;
    font-weight: normal;
    src: url("./fonts/PlusJakartaSans-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: 'PlusJakartaSans';
    font-style: normal;
    font-weight: 300;
    src: url("./fonts/PlusJakartaSans-Light.ttf") format("truetype");
}

@font-face {
    font-family: 'PlusJakartaSans';
    font-style: italic;
    font-weight: 300;
    src: url("./fonts/PlusJakartaSans-LightItalic.ttf") format("truetype");
}

@font-face {
    font-family: 'PlusJakartaSans';
    font-style: normal;
    font-weight: 500;
    src: url("./fonts/PlusJakartaSans-Medium.ttf") format("truetype");
}

@font-face {
    font-family: 'PlusJakartaSans';
    font-style: italic;
    font-weight: 500;
    src: url("./fonts/PlusJakartaSans-MediumItalic.ttf") format("truetype");
}

@font-face {
    font-family: 'PlusJakartaSans';
    font-style: normal;
    font-weight: normal;
    src: url("./fonts/PlusJakartaSans-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'PlusJakartaSans';
    font-style: normal;
    font-weight: bold;
    src: url("./fonts/PlusJakartaSans-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: 'PlusJakartaSans';
    font-style: italic;
    font-weight: bold;
    src: url("./fonts/PlusJakartaSans-SemiBoldItalic.ttf") format("truetype");
}

body {
    margin: 0;
    font-family: 'PlusJakartaSans' !important;
}

/* p{
    color: white;
} */
