.modalContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    z-index: 100;
    top: 0;

    .outside {
        width: 100%;
        height: 100vh;
        position: absolute;
        z-index: 2;
        background: #181829b0;
        top: 0;
    }

    
}